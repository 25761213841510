import axios from "../../utilities/axios";
import React from "react";
import { Button, Notification, Input, SelectPicker, Col } from "rsuite";
import { Event } from "../Event";
import { withRouter } from "react-router";
import { ConfirmModal } from "../../components/confirmModal/ConfirmModal";


class DibiFooterComponnent extends React.Component<any, {}> {
    public state = {
        show: false,
        showEmail: false,
        loading: false,
        showSituation: false,
        situacaoModal: "",
        started: false,
        loadingClockIn: false,
        reproveShowSituation: false,
        reprovetionText: 'sem valor',
        showDisclaimModal: false
    };

    savePsm = () => {
        this.props.onSubmit(() => {
            this.setState({ loading: true })
        },
            () => {
                this.setState({ loading: false });
            })
    }
    alteraSituacao = () => {
        this.setState({ showSituation: true })
    }

    trocaStatus = async (status: 'avanca' | 'voltar' = 'avanca') => {
        // console.log(this.props.state.checked)
        const view = this.props.location.pathname.includes('dibis') ? 'dibis' : 'psms';
        if (!this.props.match.params.id) {
            Notification.error({
                title: "Erro!",
            });
            return;
        }
        this.setState({ loading: true });
        if (status == 'avanca') {
            axios
                .post(`/api/v1/situation/controller/dibi/${this.props.match.params.crud}/${this.props.match.params.id}/${this.props.values.formValues.psm_status_fk_dibi_situation_id}`, { data: this.props.values.formValues })
                .then((res) => {
                    if (res.data.status === 201) {
                        let labels: string = "";
                        for (const item of res.data.errors) {
                            labels += `> ${item.label}, `;
                        }
                        labels = labels.slice(0, -2)
                        Notification.error({
                            title: "Campos obrigatorios para avançar situação!",
                            description: labels,
                            placement: "bottomEnd",
                            duration: 10000,
                        });
                        this.setState({ loading: false });
                        return;
                    }
                    if (res.data.status === 200) {
                        this.props.onSubmit(
                            () => {
                                this.setState({ loading: true });
                                // alert("SAVED !");
                                var currentRouteSplited = this.props.history.location.pathname.split("/");
                                var currentRoute = currentRouteSplited.splice(0, currentRouteSplited.length - 1).join("/");

                                axios
                                    .get(`/api/v1/situation/${status}/${this.props.values.oldValues.psm_status_fk_dibi_situation_id}/${this.props.match.params.id}/${view}`)
                                    .then((async res => {
                                        console.log('mudei situacao """:')
                                        if (res.status === 200 && res.data.status == 200) {
                                            await Notification.success({
                                                title: "Sucesso!",
                                                description: res.data.message,
                                                duration: 3500,
                                            });
                                            setTimeout(() => {
                                                this.props.history.push(`/dashboard/${this.props.match.params.module}/${this.props.match.params.crud}/${this.props.match.params.id}?tab=Main`);
                                                this.props.history.go(0)
                                            }, 3700)

                                        }
                                        if (res.data.status == 201) {
                                            Notification.warning({
                                                title: "Atenção!",
                                                description: res.data.message,
                                            });
                                        }
                                    }))
                                    .catch((err) => {
                                        console.log({ err })
                                    })
                                    .finally(() => {
                                        this.setState({ loading: false });
                                    });
                            },
                            () => {
                                this.setState({ loading: false });
                            },
                        );
                    }
                })
                .catch((err) => {
                    console.log('error :::: ', err)
                })
        }
        if (status == 'voltar') {
            var existRm: boolean = false;
            await axios.get('/api/v1/psm/verifyrm', {
                params: {
                    psmNumber: this.props.values.formValues.psm_os_number,
                }
            }).then((response: any) => {
                // console.log("valor do response", response)
                existRm = true;
            })
                .catch((e) => {
                    console.log(e)
                })
            // console.log("Valor do existRm", existRm)
            if (existRm) {
                Notification.error({
                    title: "Erro!",
                    description: 'Não foi possível voltar a PSM pois existe RMs que foram criadas para essa PSM',
                    duration: 3500,
                });
                this.setState({ loading: false });
                return;
            }
            axios
                .get(`/api/v1/situation/${status}/${this.props.values.oldValues.psm_status_fk_dibi_situation_id}/${this.props.match.params.id}/${view}`)
                .then((async res => {
                    if (res.status === 200 && res.data.status == 200) {
                        await Notification.success({
                            title: "Sucesso!",
                            description: res.data.message,
                            duration: 3500,
                        });
                        this.props.history.push(`/dashboard/${this.props.match.params.module}/${this.props.match.params.crud}/${this.props.match.params.id}?tab=Main`);
                        this.props.history.go(0)

                    }
                    if (res.data.status == 201) {
                        Notification.warning({
                            title: "Atenção!",
                            description: res.data.message,
                        });
                    }
                }))
                .finally(() => {
                    this.setState({ loading: false });
                });
        }
    }

    aprovedAndReproved = async (status: 'aprovar' | 'reprovar' | 'encerrar') => {
        if (this.props.values.formValues.person.person_type_is_prospect == true && status == 'aprovar') {
            this.setState({ loading: true });
            Notification.error({
                title: "Não é possível aprovar a PSM",
                description: "Tipo do cliente é Prospect",
                placement: "bottomEnd",
                duration: 5000,
            });
            this.setState({ loading: false });
            return;
        }
        if (status == 'encerrar') {
            this.setState({ loading: true });

            let existRm: boolean = false;

            await axios.get('/api/v1/psm/verifyrm', {
                params: {
                    psmNumber: this.props.values.formValues.psm_os_number,
                }
            }).then((response: any) => {

                existRm = true;
            })
                .catch((e) => {
                    console.log(e)
                })
            if (existRm) {
                Notification.error({
                    title: "Erro!",
                    description: 'Não foi possível encerrar a PSM, pois existe RM já criada para esse registro.',
                    duration: 4000,
                });
                this.setState({ loading: false });
                return;
            }

            if (
                (this.props?.values?.formValues?.psm_closure == null ||
                    this.props?.values?.formValues?.psm_closure == undefined ||
                    this.props?.values?.formValues?.psm_closure == '') &&
                status == 'encerrar'
            ) {
                this.setState({ loading: true });

                Notification.warning({
                    title: "Erro!",
                    description: "Por favor preencher o motivo do encerramento!",
                    placement: "topEnd",
                    duration: 5000,
                });
                this.setState({ loading: false });
                return;
            }
        }
        this.setState({ loading: true });
        this.props.onSubmit(
            () => {
                axios
                    .post(`/api/v1/situation/controller/dibi/${this.props.match.params.crud}/${this.props.match.params.id}/${this.props.values.formValues.psm_status_fk_dibi_situation_id}/${status}`, { data: this.props.values.formValues })
                    .then((res) => {
                        if (res.data.status === 201) {
                            let labels: string = "";
                            for (const item of res.data.errors) {
                                labels += `> ${item.label}, `;
                            }
                            labels = labels.slice(0, -2)
                            Notification.error({
                                title: "Campos obrigatorios para avançar situação!",
                                description: labels,
                                placement: "bottomEnd",
                                duration: 3000,
                            });
                            this.setState({ loading: false });
                            return;
                        }
                        if (res.data.status === 200) {
                            axios
                                .get("/api/v1/estimates/statu/" + status + "/" + this.props.printId + "/dibis", { params: { text: this.state.reprovetionText } })
                                .then((res => {
                                    if (res.status === 200 && res.data.status) {
                                        Notification.success({
                                            title: 'Sucesso!',
                                            description: 'Situação Alterada com Sucesso!'
                                        })
                                        this.props.history.go(0);
                                    }
                                }))
                                .catch((err) => {
                                    Notification.error({
                                        title: 'Error!',
                                        description: err.response.data.message
                                    })
                                })
                                .finally(() => {
                                    this.setState({ loading: false });
                                });
                        }
                    })
                    .catch((err) => {
                        console.log('error :::: ', err)
                    })
            })
    }


    gerarRevisaoDibi = () => {
        axios
            .get(`/api/v1/dibi/save-revision/${this.props.printId}`)
            .then((res) => {
                if (res.status === 200 && res.data.status) {
                    this.props.onSubmit(
                        () => {
                            this.setState({ loading: true });
                            axios
                                .get("/api/v1/dibi/revisao/" + this.props.printId)
                                .then((res) => {
                                    // console.log("Somente revisão", res)
                                    if (res.status === 200 && res.data.status) {
                                        this.props.history.push(0);
                                        // window.open("http://localhost:25567" + res.data.output.path, "_blank");
                                        // alert(JSON.stringify(res.data.output.path));
                                    }
                                })
                                .finally(() => {
                                    this.setState({ loading: false });
                                });
                        },
                        () => {
                            this.setState({ loading: false });
                        },
                    );
                } else {
                    Notification.warning({
                        title: "Aviso",
                        description: res.data.message
                    })
                    return
                }
            })
            .catch((err) => {
                this.setState({ loading: false });
            })
            .finally(() => { });
    }

    compairValuesDibiRevision = () => {
        // console.log('test >>>>>',this.props)
        axios
            .post(`/api/v1/dibi/compair-revision`, {
                id: this.props.printId,
                data: this.props.values.formValues
            })
            .then((res) => {
                console.log(res)
                if (res.data.status == 200) {
                    this.setState({ show: true });
                } else {
                    this.props.onSubmit()
                }
            })
    }

    printPropositure = () => {
        this.props.onSubmit(
            () => {
                this.setState({ loading: true });

                axios
                    .get("/api/v1/dibi/print-propositure/" + this.props.printId + '/technical')
                    .then((res) => {
                        if (res.data && res.data.url) {
                            console.log(res.data)
                            window.open('https://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                        }
                    })
                    .catch((e) => {
                        Notification.error({
                            title: "[" + e.response.status + "] Erro",
                            description: e.response.data.message ? e.response.data.message : e.message,
                        });
                    })
                    .finally(() => {
                        this.setState({ loading: false });
                    });
            },
            () => {
                this.setState({ loading: false });
            },
        );
    }

    renderChangeButtonSituation = () => {
        const output: any = [];

        if (this.props.values.formValues.psm_status_fk_dibi_situation_id == 10 && this.props.values.formValues.psm_checked_send_mail == true) {
            output.push(<>
                <Button loading={this.state.loading} color={"blue"} onClick={() => this.alteraSituacao()} className="ml-4">
                    <i className="fas fa-tasks fa-fw mr-2"></i>
                    Mudar Situação
                </Button>
            </>);
        } else {
            if (this.props.values.formValues.psm_status_fk_dibi_situation_id !== 5) {
                output.push(<>
                    <Button loading={this.state.loading} color={"blue"} onClick={() => this.alteraSituacao()} className="ml-4">
                        <i className="fas fa-tasks fa-fw mr-2"></i>
                        Mudar Situação
                    </Button>
                </>);
            }
        }



        return output
    }

    componentDidMount(): void {
        // console.log('Props values >>>> ', this.props)
        // console.log("valor do thisprops", this.props)

    }
    render() {
        // console.log('teste :::> ',this.props.values.formValues.psm_status_fk_dibi_situation_id)
        return (<>
            {/* <Button loading={this.state.loading} onClick={() => savePsm()} color={"green"} className="ml-4">
                <i className="fas fa-save fa-fw mr-2"></i>
                Gravar
            </Button> */}
            < ConfirmModal
                loading={this.state.loading}
                onCancel={() => {
                    this.setState({ show: false });

                    this.props.onSubmit(() => {
                        this.setState({ show: false });
                    });
                }}
                showbotaoclose={true}
                funcaoclose={() => {
                    this.setState({ show: false });
                }}
                onConfirm={this.gerarRevisaoDibi}
                title={"Valores alterados!"}
                cancelText={"Apenas gravar"}
                cancelColor={"blue"}
                confirmColor={"orange"}
                confirmText={"Gerar revisão"}
                content="Os valores da proposta foram alterados, deseja gerar revisão?"
                show={this.state.show}
            />
            {/* <ConfirmModal
                loading={this.state.loading}
                onCancel={() => {
                    this.setState({
                        reproveShowSituation: false,
                    })
                }}
                body={
                    <Input componentClass="textarea" size="lg" onBlur={(event: any) => this.setState({ reprovetionText: event.target.value })} style={{ marginBottom: 20 }} />
                }
                onConfirm={() => this.aprovedAndReproved('reprovar')}
                cancelColor={"red"}
                showConfirm={true}
                hiddenInput={true}
                confirmText={"Gravar"}
                onCloseButton={true}
                content={""}
                confirmColor={"green"}
                title={"Informe o motivo da reprovação da PSM"}
                show={this.state.reproveShowSituation}
            /> */}

            < Button loading={this.state.loading} onClick={() => this.compairValuesDibiRevision()} color={"green"} className="ml-4" >
                <i className="fas fa-save fa-fw mr-2"></i>
                Gravar
            </Button >
            <Button loading={this.state.loading} color={"violet"} onClick={() => this.printPropositure()} className="ml-4">
                <i className="fas fa-check-circle fa-fw mr-2"></i>
                Imprimir Proposta
            </Button>
            {
                this.props.values.formValues.psm_status_fk_dibi_situation_id == 2 && (<>
                    <Button loading={this.state.loading} color={"green"} onClick={() => this.aprovedAndReproved('aprovar')} className="ml-4">
                        <i className="fas fa-check-circle fa-fw mr-2"></i>
                        Aprovar
                    </Button>
                </>)
            }
            {
                this.props.values.formValues.psm_status_fk_dibi_situation_id == 2 && (<>
                    <Button loading={this.state.loading} color={"red"} onClick={() => this.aprovedAndReproved('reprovar')} className="ml-4">
                        {/* <Button loading={this.state.loading} color={"red"} onClick={() => this.setState({ reproveShowSituation: true })} className="ml-4"> */}
                        <i className="fas fa-times-circle fa-fw mr-2"></i>
                        Reprovar
                    </Button>
                </>)
            }
            {
                this.props.values.formValues.psm_status_fk_dibi_situation_id == 3 && (<>
                    <Button style={{ color: '#fff', backgroundColor: '#FF5733' }} loading={this.state.loading} onClick={() => this.aprovedAndReproved('encerrar')} className="ml-4">
                        {/* <Button loading={this.state.loading} color={"red"} onClick={() => this.setState({ reproveShowSituation: true })} className="ml-4"> */}
                        <i className="fas fa-check-circle fa-fw mr-2"></i>
                        Encerrar
                    </Button>
                </>)
            }
            <ConfirmModal
                loading={this.state.loading}
                onCancel={() => {
                    this.setState({
                        showSituation: false,
                    })
                }}
                body={<>
                    <Button loading={this.state.loading} color='orange' onClick={() => this.trocaStatus('voltar')}>VOLTAR STATUS</Button>
                    {[1, 10].includes(this.props.values.formValues.psm_status_fk_dibi_situation_id) && (
                        <Button loading={this.state.loading} color='blue' style={{ marginLeft: 20 }} onClick={() => this.trocaStatus('avanca')}>AVANÇAR STATUS</Button>
                    )}
                </>}
                cancelColor={"red"}
                showConfirm={false}
                cancelText={"Fechar"}
                onCloseButton={true}
                content={""}
                returnText={"Voltar Situação"}
                advancedText={"Avançar Situação"}
                title={"Avança situação de ordem de serviço"}

                show={this.state.showSituation}
            />
            {this.renderChangeButtonSituation()}
        </>)

    }
}

const DibiFooterWithRouter = withRouter(DibiFooterComponnent);

export class DibiFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <DibiFooterWithRouter onSubmit={onSubmit} printId={this.globalState.oldValues.psm_id} values={this.globalState} />
            </>
        );
    };
}
