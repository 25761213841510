import React from "react";
import { Whisper, Tooltip, Button, Modal, Table, Notification } from "rsuite";
import { ModalImportAttachmentUser } from "./ModalImportAttachmentPerson";
import 'rsuite/dist/styles/rsuite-default.css'; // Certifique-se de ter os estilos importados
import axios from "../../../utilities/axios";
import TablePagination from "rsuite/lib/Table/TablePagination";

const { Column, HeaderCell, Cell } = Table;

type State = {
    titleModal: string;
    showModal: boolean;
    functionName: string;
    showLogsModal: boolean;
    data: any[];
    page: number;  // Estado adicionado para controlar a página atual
    displayLength: number;  // Estado adicionado para controlar o número de registros por página
    isLoading: boolean;
}

export class PersonBrowserlize extends React.Component<any, State> {
    public state: State = {
        titleModal: '',
        showModal: false,
        functionName: '',
        showLogsModal: false, // Inicialmente, o modal de logs está fechado
        data: [], // Inicialmente, nenhum dado
        page: 1,  // Estado adicionado para controlar a página atual
        displayLength: 10,  // Estado adicionado para controlar o número de registros por página
        isLoading: false
    }

    componentDidMount(): void {
        // console.log('props ::> ', this.props)
    }

    onOpenModal = ({ title, functionName }: { title: string, functionName: string }) => {
        this.setState({
            showModal: true,
            titleModal: title,
            functionName: functionName
        })
    }

    onOpenLogs = async ({ title, functionName }: { title: string, functionName: string }) => {
        await axios.get(`/api/v1/import/conference/getImportLogsSellers/`)
            .then((res: any) => {
                console.log("valor do res ::>", res)
                this.setState({
                    showLogsModal: true,
                    data: res.data
                });

            })
    }

    getAllSellers = async () => {

        this.setState({ isLoading: true });

        await axios.get(`/api/v1/import/conference/getAllSellers/`, { timeout: 200000 })
            .then((res: any) => {
                console.log("valor do res ::>", res);

                // const url = 'https://' + window.location.host.split('25565').join('25567') + res.data.data;

                setTimeout(() => {
                    Notification.success({
                        title: 'Sucesso',
                        description: 'Vendedores exportados com sucesso!',
                        duration: 5000,
                    });
                    this.setState({ isLoading: false });
                    window.open('https://' + window.location.host.split('25565').join('25567') + res.data.data);
                }, 5000)
            })
    }

    onCloseModal = () => {
        this.setState({
            showModal: false
        })
    }

    onCloseLogsModal = () => {
        this.setState({
            showLogsModal: false
        })
    }

    handleChangePage = (page: number) => {
        this.setState({ page });
    }

    handleChangeLength = (displayLength: number) => {
        this.setState({ page: 1, displayLength });
    }


    render(): React.ReactNode {
        const { page, displayLength, data } = this.state;
        const filteredData = data.slice((page - 1) * displayLength, page * displayLength);

        return (
            <>
                <ModalImportAttachmentUser
                    title={this.state.titleModal}
                    crud={this.props.name}
                    show={this.state.showModal}
                    functionNameImport={this.state.functionName}
                    onCLoseModal={this.onCloseModal}
                />
                <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Importação de Vendedores</Tooltip>}>
                    <Button style={
                        {
                            backgroundColor: '#dcdcdc'
                        }
                    }
                        className="mr-2"
                        onClick={() => this.onOpenModal(
                            {
                                title: 'Usuários',
                                functionName: 'importUsers'
                            }
                        )}
                    >
                        <i className="fa fa-upload"></i>
                    </Button>
                </Whisper>
                <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Logs de Importação</Tooltip>}>
                    <Button style={
                        {
                            color: 'snow',
                            backgroundColor: '#4682B4'
                        }
                    }
                        className="mr-2"
                        onClick={() => this.onOpenLogs({ title: 'Logs', functionName: 'viewLogs' })}
                    >
                        <i className="fa fa-list-alt"></i>
                    </Button>
                </Whisper>

                <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Exportar Vendedores</Tooltip>}>
                    <Button
                        style={{
                            color: 'snow',
                            backgroundColor: '#3CB371'
                        }}
                        className="mr-2"
                        onClick={this.getAllSellers}
                        loading={this.state.isLoading}  // Mostrar estado de carregamento
                        disabled={this.state.isLoading}  // Desabilitar o botão enquanto está carregando
                    >
                        <i className="fa fa-file"></i>
                    </Button>
                </Whisper>

                {/* <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Modelo Excel</Tooltip>}>
                    <Button style={
                        {
                            color: 'snow',
                            backgroundColor: '#3CB371'
                        }
                    }
                        className="mr-2"
                        onClick={() => window.open('https://' + window.location.host.split('25565').join('25567') + '/storage/modelo.xlsx')}

                    >
                        <i className="fa fa-file"></i>
                    </Button>
                </Whisper> */}
                {/* Modal de Logs */}
                {this.state.showLogsModal && (
                    <Modal full show={true} onHide={this.onCloseLogsModal}>
                        <Modal.Header>
                            <Modal.Title>Logs de Importação</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table
                                height={400}
                                data={filteredData}
                                onRowClick={data => {
                                    console.log(data);
                                }}
                            >
                                <Column width={70} align="center" fixed>
                                    <HeaderCell>ID</HeaderCell>
                                    <Cell dataKey="import_sellers_log_id" />
                                </Column>

                                <Column width={200} fixed>
                                    <HeaderCell>Usuário</HeaderCell>
                                    <Cell dataKey="import_seller_log_user" />
                                </Column>

                                <Column width={200}>
                                    <HeaderCell>Data</HeaderCell>
                                    <Cell dataKey="import_seller_log_date" />
                                </Column>

                                <Column width={200}>
                                    <HeaderCell>Link</HeaderCell>
                                    <Cell>
                                        {(rowData: { file_path: string; file_name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) => (
                                            <Button
                                                appearance="link"
                                                onClick={() => window.open('https://' + window.location.host.split('25565').join('25567') + rowData.file_path)}

                                            >
                                                {rowData.file_name}
                                            </Button>
                                        )}
                                    </Cell>
                                </Column>
                            </Table>
                            <TablePagination
                                lengthMenu={[
                                    { value: 10, label: '10 / página' },
                                    { value: 20, label: '20 / página' },
                                    { value: 30, label: '30 / página' }
                                ]}
                                activePage={page}
                                displayLength={displayLength}
                                total={data.length}
                                onChangePage={this.handleChangePage}
                                onChangeLength={this.handleChangeLength}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.onCloseLogsModal} appearance="subtle">Fechar</Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </>
        );
    }
}
