import axios from "../../../utilities/axios";
import { Event } from "../../Event";
import { Notification } from "rsuite";



const calculaValorTotal = (inicial: number, descontoPercentage: number = 0, descontoValue: number = 0, itens: number) => {
    var valorTotal = inicial;
    var targetDescontoPercentage = 0;
    var targetDescontoValue = 0;
    if (descontoPercentage > 0) {
        valorTotal = valorTotal - (valorTotal * descontoPercentage) / 100;
        targetDescontoPercentage = descontoPercentage;
        targetDescontoValue = inicial - valorTotal;
    } else if (descontoValue > 0) {
        valorTotal = valorTotal - descontoValue;
        targetDescontoValue = descontoValue;
        targetDescontoPercentage = 100 - (valorTotal * 100) / (valorTotal + descontoValue);
    }
    // const valorTotal = inicial - (inicial * descontoPercentage / 100) - descontoValue;

    return {
        valorTotal,
        percentual: targetDescontoPercentage,
        desconto: targetDescontoValue,
        valorDeDescontoUnitario: (valorTotal / itens)
    };
};


/* Grid de realção de Serviços */
export class PsmRelationServiceEvents extends Event {
    public onLoad = async (value: any, setField: any, setState: any) => {
        let boundRelationProduct = this.globalProps.values.psm_relation_service_bound;

        const fieldColumns = this.globalProps.form;
        const situationIdDibi = this.globalProps.parentValues.psm_status_fk_dibi_situation_id;

        // if (this?.globalProps?.isEdit !== null) {
        //     if (['', undefined, null].includes(this.globalProps.values.psm_relation_service_rm_bond)) {

        //         this.setState("loadings", [
        //             'psm_relation_service_rm_bond',
        //             'psm_relation_service_reproving'
        //         ])
        //         await axios.get('/api/v1/psm/verifyrm', {
        //             params: {
        //                 idPsm: this.globalProps.parentValues.psm_id,
        //                 iReference: this.globalProps.values.psm_relation_service_intern_reference,
        //                 iDescription: this.globalProps.values.psm_relation_service_item_description
        //             }
        //         }).then((response: any) => {
        //             const dataRes = response.data
        //             this.setState("psm_relation_service_reproving", { readonly: true })
        //             this.setFieldValue("psm_relation_service_rm_bond", dataRes.data[0]?.material_requisition_os_number)


        //         })
        //             .catch((e) => {
        //                 console.log('Err LLL<', e)
        //             })
        //     }
        // }


        let disbledConditionBelow: boolean = false

        const fieldColumnsToEdit: any = ['psm_relation_service_deadline', 'psm_relation_service_revenues_date', 'psm_relation_service_base_price', 'psm_relation_service_politic', 'psm_relation_service_margin',
            'psm_relation_service_value_uniti', 'psm_relation_service_discount', 'psm_relation_service_discount_percent', 'psm_relation_service_show_budget'
        ];


        if(this?.globalProps?.isEdit == undefined || this?.globalProps?.isEdit == null){
            this.setFieldValue("psm_relation_service_recomended", true)
        }

        if (this.globalProps.match.url.includes('commercials') && [undefined,null].includes(this?.globalProps?.isEdit)) {
            this.setFieldValue('psm_relation_service_origin_name', 'COMERCIAL')
        }

        if (this.globalProps.match.params.crud !== "dibis") {
            const dadosPsm = this.globalProps.values;
            if (this.globalProps.isEdit !== null) {
                if (this.globalProps.parentValues.psm_status_fk_situation_id >= 5 && dadosPsm.psm_relation_service_id) {
                    const matchParams = this.globalProps.match.params;
                    await axios.get('/api/v1/check-item/product', {
                        params: {
                            crud: matchParams.crud,
                            idPsm: matchParams.id,
                            idProduct: dadosPsm.psm_relation_service_id,
                            tableName: this.globalProps.name
                        }
                    }).then((response: any) => {
                        if (response.data.status == 200) {
                            Notification.info({
                                title: 'Permitido Alteração',
                                description: response.data.message
                            })
                        }
                    })
                        .catch((e) => {
                            Notification.info({
                                title: 'Info',
                                description: e.response.data.message
                            })

                            this.setField('psm_relation_service_fk_select_id', { hidden: true })
                            this.setField('psm_relation_service_item_description', { hidden: false, disabled: true, readonly: true })
                            for (const i in fieldColumnsToEdit) {
                                if (i) {
                                    this.setField(fieldColumnsToEdit[i], { readonly: false, disabled: false, canEdit: true })
                                }
                                continue;
                            }
                        })
                }
            }
        }

        if (this.globalProps.match.params.crud == "dibis") {
            const dadosPsm = this.globalProps.values;
            if (this.globalProps.isEdit !== null) {
                const matchParams = this.globalProps.match.params;
                await axios.get('/api/v1/check-item/product', {
                    params: {
                        crud: matchParams.crud,
                        idPsm: matchParams.id,
                        idProduct: dadosPsm.psm_relation_service_id,
                        tableName: this.globalProps.name
                    }
                }).then((response: any) => {
                    if (response.data.status == 200) {
                        Notification.info({
                            title: 'Permitido Alteração',
                            description: response.data.message
                        })
                    }
                })
                    .catch((e) => {
                        Notification.info({
                            title: 'Info',
                            description: e.response.data.message
                        })

                        this.setField('psm_relation_service_fk_select_id', { hidden: true })
                        this.setField('psm_relation_service_item_description', { hidden: false, disabled: true, readonly: true })
                        for (const i in fieldColumnsToEdit) {
                            if (i) {
                                this.setField(fieldColumnsToEdit[i], { readonly: false, disabled: false, canEdit: true })
                            }
                            continue;
                        }
                    })
            }
        }

        this.setState("loadings", [
            "psm_relation_service_quantity",
            "psm_relation_service_fk_fiscal_classification_id",
            "psm_relation_service_fk_select_id"
        ])
        if (this.globalProps.values.psm_relation_service_registered == false) {
            if (!this.globalProps.values.psm_relation_service_origin_name) {
                this.setField("psm_relation_service_quantity", { readonly: false });
                this.setField("psm_relation_service_fk_fiscal_classification_id", { readonly: false });
                this.setField("psm_relation_service_fk_select_id", { disabled: false })
                this.setState("loadings", []);
            }

            var classificacaoFiscal = this.globalProps.values.psm_relation_service_fk_fiscal_classification_id;
            if (classificacaoFiscal !== null) {
                this.setFieldValue("psm_relation_service_fk_fiscal_classification_id", parseInt(this.globalProps.values.psm_relation_service_fk_fiscal_classification_id));
                this.setState("loadings", ["psm_relation_service_fk_fiscal_classification_id",]);
                this.setFieldValue("psm_relation_service_fk_fiscal_classification_id", parseInt(this.globalProps.values.psm_relation_service_fk_fiscal_classification_id));
                this.setState("loadings", []);
            }
            if (this.globalProps.values.psm_relation_product_registered == true) {
                this.setField('psm_relation_product_fk_fiscal_classification_id', {
                    readonly: false
                })
            }
        }
        this.setState("loadings", []);
    }
}



export class PsmRelationServiceBasePriceEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        var dadosPsm = this.globalProps.values;

        console.log({ value })

        if (value == 0 || value == null) {
            this.setState("loadings", [
                "psm_relation_service_value_all"
            ]);

            console.log('entrou no if')
            this.setFieldValue("psm_relation_service_value_all", 0.00000001); //0
            this.setFieldValue("psm_relation_service_value_uniti", 0.00000001);
            this.setFieldValue("psm_relation_service_discount_value_all", 0.00000001)
            // console.log({ dadosPsm })

            this.setState("loadings", []);
        }
        if (value > 0 && dadosPsm?.psm_relation_service_quantity > 0) {
            this.setFieldValue('psm_relation_service_value_all', value * dadosPsm?.psm_relation_service_quantity)
            this.setFieldValue('psm_relation_service_value_uniti', value)
        }
        // console.log({ dadosPsm })
    }
}


export class PsmRelationServiceFkSelectIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            this.setState("loadings", [
                "psm_relation_service_intern_reference",
                "psm_relation_service_product_id",
                "psm_relation_service_item_description",
                "psm_relation_service_base_price",
                "psm_relation_service_politic",
                "psm_product_registration_margin",
                "psm_relation_service_value_uniti",
                "psm_relation_service_fk_fiscal_classification_id",
                "psm_relation_service_fk_measure_unit_id",
                "psm_relation_service_manufacture_code",
                "psm_relation_service_fk_select_id"

            ]);

            axios.get("/api/v1/products/" + value.valueFk).then(async (res) => {
                var margin = res.data.item.product_ideal_margin;
                var marginDiscount = margin !== undefined ? (margin / 1000) * 300 + (margin / 100 * 100) : 0;



                if (res && res.data && res.data.item) {
                    this.setFieldValue("psm_relation_service_intern_reference", res.data.item.product_intern_reference);
                    this.setFieldValue("psm_relation_service_product_id", JSON.stringify(res.data.item.product_id));
                    this.setFieldValue("psm_relation_service_item_description", res.data.item.product_description);
                    this.setFieldValue("psm_relation_service_fk_measure_unit_id", res.data.item.product_fk_measure_unit_id);
                    this.setFieldValue("psm_relation_service_fk_select_id", value.valueFk)
                    if (value.isExchangeValue) {
                        this.setFieldValue("psm_relation_service_base_price", res.data.item.product_price_min);
                        this.setFieldValue("psm_relation_service_politic", margin);
                        this.setFieldValue("psm_relation_service_margin", marginDiscount);
                        this.setFieldValue("psm_relation_service_value_uniti", res.data.item.product_price_min);
                    }
                    if ((res.data?.item.service_fk_fiscal_classification_id !== null) && (res.data?.item.service_fk_fiscal_classification_id !== undefined)) {
                        this.setFieldValue("psm_relation_service_fk_fiscal_classification_id", res.data.item.product_fk_fiscal_classification_id);
                    }
                    if (![null, undefined].includes(res.data.item?.product_manufacturer_code)) {
                        this.setFieldValue("psm_relation_service_manufacture_code", res.data.item?.product_manufacturer_code);
                    }
                    this.setState("loadings", ["psm_relation_service_fk_fiscal_classification_id",]);
                    await axios
                        .get(`/api/v1/fiscal-classification/${res.data?.item.product_fk_fiscal_classification_id}`)
                        .then((res) => {
                            this.setFieldValue("psm_relation_service_fk_fiscal_classification_id", { value: res.data?.item.fiscal_classification_id, label: `${res.data.item.fiscal_classification_abbreviation} - ${res.data.item.fiscal_classification_name}` });
                        })
                    this.setState("loadings", []);


                    // this.setState("loadings",["psm_relation_service_fk_fiscal_classification_id"])
                    // this.setFieldValue("psm_relation_service_fk_fiscal_classification_id", res.data.item.product_fk_fiscal_classification_id);
                    // this.setState("loadings",["psm_relation_service_fk_fiscal_classification_id"])

                }

            })
                .catch((e) => { })
                .finally(() => {
                    this.setState("loadings", []);
                });
        }
    }
}


export class PsmRelationServiceRegisteredEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        if (value == true) {
            await this.setState("loadings", [
                "psm_relation_service_item_description",
                "psm_relation_service_intern_reference",
                "psm_relation_service_product_id",
                "psm_relation_service_fk_select_id",
                "psm_relation_service_fk_measure_unit_id",
                "psm_relation_service_base_price",
                "psm_relation_service_politic",
                "psm_relation_service_fk_fiscal_classification_id",
                "psm_relation_service_margin"
            ]);
            this.setFieldValue("psm_relation_service_item_description", "");
            this.setFieldValue("psm_relation_service_intern_reference", "");
            this.setFieldValue("psm_relation_service_fk_measure_unit_id",);
            // this.setFieldValue("psm_relation_service_base_price", 0);
            // this.setFieldValue("psm_relation_service_politic", 0);
            // this.setFieldValue("psm_relation_service_margin", 0);
            // this.setFieldValue("psm_relation_service_value_uniti", 0);
            this.setState("loadings", "psm_relation_service_value_uniti")
            this.setFieldValue("psm_relation_service_fk_fiscal_classification_id",);
            this.setState("loadings", "psm_relation_service_fk_fiscal_classification_id")
            this.setFieldValue("psm_relation_service_product_id", "");
            this.setFieldValue("psm_relation_service_fk_select_id",);



            this.setState("loadings", [])
        };
    }
}

export class PsmRelationServiceQuantityEvents extends Event {
    public onInit = () => {
        // console.log("Helooooou")
    }
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        ;
        var dadosPsm = this.globalProps.values;
        if (value) {
            this.setState("loadings", [
                "psm_relation_service_value_all"
            ]);
            let sum: number
            if ((dadosPsm?.psm_relation_service_margin !== undefined) && (dadosPsm?.psm_relation_service_margin !== 0)) {
                sum = (((dadosPsm?.psm_relation_service_margin / 100) * dadosPsm?.psm_relation_service_base_price) + dadosPsm?.psm_relation_service_base_price) * value
                this.setFieldValue("psm_relation_service_value_all", sum);
                this.setFieldValue("psm_relation_service_value_uniti", ((dadosPsm?.psm_relation_service_margin / 100) * dadosPsm?.psm_relation_service_base_price) + dadosPsm?.psm_relation_service_base_price)
            } else {
                if ((dadosPsm.psm_relation_service_value_uniti !== 0) && (dadosPsm.psm_relation_service_value_uniti !== undefined)) {
                    sum = value * dadosPsm.psm_relation_service_value_uniti;
                    this.setFieldValue("psm_relation_service_value_all", sum);
                }
            }
            this.setState("loadings", []);
        }
    };
}



export class PsmRelationServiceMarginEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        let dadosPsm = this.globalProps.values;
        if (['0', '', undefined, null].includes(value)) {
            this.setFieldValue('psm_relation_service_margin', 0)
        }
        if (value) {
            if ((![undefined, null, 0].includes(dadosPsm?.psm_relation_service_base_price)) && (![undefined, null, 0].includes(dadosPsm?.psm_relation_service_quantity))) {
                let sum: number
                sum = (((value / 100) * dadosPsm?.psm_relation_service_base_price) + dadosPsm?.psm_relation_service_base_price) * dadosPsm?.psm_relation_service_quantity;
                console.log()
                this.setFieldValue("psm_relation_service_value_all", sum);
                this.setFieldValue("psm_relation_service_value_uniti", ((value / 100) * dadosPsm?.psm_relation_service_base_price) + dadosPsm?.psm_relation_service_base_price);
            }
        }
    };
}

export class PsmRelationServiceValueUnitiEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        let dadosPsm = this.globalProps.values;
        if (value) {
            this.setState("loadings", [
                "psm_relation_service_value_all"
            ]);
            if (![undefined, 0].includes(dadosPsm.psm_relation_service_quantity)) {
                var sum: number = value * dadosPsm.psm_relation_service_quantity;
                this.setFieldValue("psm_relation_service_value_all", sum)
            }

            this.setState("loadings", []);
        }
    };
}



export class PsmRelationServiceDiscountEvents extends Event {
    public onBlur = (value: any) => {
        if (value > 0) {
            console.log('Executei')
            var dadosPsm = this.globalState.formValues;
            var desconto = parseFloat(value);
            var inicial = dadosPsm.psm_relation_service_quantity * dadosPsm.psm_relation_service_value_uniti;

            var resultado = calculaValorTotal(inicial, 0, desconto, dadosPsm.psm_relation_product_quantity);
            if (resultado.valorTotal > 0) {
                this.setFieldValue("psm_relation_service_discount_value_all", resultado.valorTotal);
                this.setFieldValue("psm_relation_service_discount_percent", resultado.percentual);
                this.setFieldValue('psm_relation_service_value_discount_uniti', resultado.valorDeDescontoUnitario)
                // this.setFieldValue("psm_discount", value.toFixed(2));
            } else {
                Notification.error({
                    title: "Valor do desconto inválido",
                });
                this.setFieldValue("psm_relation_service_discount_percent", 0);
                this.setFieldValue("psm_relation_service_discount", 0);
                this.setFieldValue("psm_relation_service_value_all", this.globalState.formValues.psm_relation_service_value_all);
            }
        }
        if ([null, 0].includes(value)) {
            this.setFieldValue("psm_relation_service_discount_value_all", 0);
            this.setFieldValue("psm_relation_service_discount_percent", 0);
            this.setFieldValue("psm_relation_service_value_discount_uniti", 0);
        }
    };
}

export class PsmRelationServiceDiscountPercentEvents extends Event {
    public onBlur = (value: any) => {
        if (value > 0) {
            var dadosPsm = this.globalState.formValues;
            var porcentagem = parseFloat(value);
            var inicial = dadosPsm.psm_relation_service_quantity * dadosPsm.psm_relation_service_value_uniti;
            var desconto = parseFloat(this.globalState.formValues.psm_service_registration_discount ?? "0");
            var resultado = calculaValorTotal(inicial, porcentagem, 0, dadosPsm.psm_relation_product_quantity);
            if (resultado.valorTotal > 0) {
                this.setFieldValue("psm_relation_service_discount_value_all", resultado.valorTotal);
                this.setFieldValue("psm_relation_service_discount", resultado.desconto);
                this.setFieldValue('psm_relation_service_value_discount_uniti', resultado.valorDeDescontoUnitario)
                // this.setFieldValue("psm_discount_percentage", value.toFixed(2));
            } else {
                Notification.error({
                    title: "Percentual de desconto inválido",
                });
                this.setFieldValue("psm_relation_service_discount_percent", 0);
                this.setFieldValue("psm_relation_service_discount", 0);
                this.setFieldValue("psm_relation_service_value_all", this.globalState.formValues.psm_relation_service_value_all);
            }
        }
        if ([null, 0].includes(value)) {
            this.setFieldValue("psm_relation_service_discount_value_all", 0);
            this.setFieldValue("psm_relation_service_value_discount_uniti", 0);
            this.setFieldValue("psm_relation_service_discount", 0);
        }
    };
}