import axios from "../../utilities/axios";
import React from "react";
import { Button, Notification, Input, SelectPicker, Col } from "rsuite";
import { Event } from "../Event";
import { withRouter } from "react-router";
import { ConfirmModal } from "../../components/confirmModal/ConfirmModal";
import { CustomModal } from "../../components/customModal/CustomModal"
import classNames from "classnames";
import { link } from "fs";


class BoxFooterComponent extends React.Component<any, {}> {
    
    public state = {
        show: false,
        showEmail: false,
        loading: false,
        showSituation: false,
        situacaoModal: "",
        started: false,
        loadingClockIn: false,
        reproveShowSituation: false,
        reprovetionText: 'sem valor',
        showDisclaimModal: false,
        to: [],
        cc: [],
        cco: [],
        files: [],
        isOpen: false,
        emailSubject: "",
        psmId: [],
        modalLoading: false,
    };

    trocaStatus = (status: 'aguardando' | 'finalizado' | 'desfazer' | 'reabrir' = 'aguardando') => {
        // console.log(this.props.state.checked)
        if (!this.props.match.params.id) {
            Notification.error({
                title: "Erro!",
            });
            return;
        }
        if (['desfazer', 'reabrir'].includes(status)) {
            axios
                .get(`/api/v1/situation/${status}/${this.props.match.params.id}`)
                .then((async res => {
                    if (res.status === 200 && res.data.status == 200) {
                        await Notification.success({
                            title: "Sucesso!",
                            description: res.data.message,
                            duration: 5000,
                        });
                        setTimeout(() => {
                            this.props.history.go(0)
                        }, 2000);
                    }
                    if (res.data.status == 201) {
                        Notification.warning({
                            title: "Atenção!",
                            description: res.data.message,
                        });
                    }
                }))
                .finally(() => {
                    this.setState({ loading: false });
                });
            return
        }
        this.setState({ loading: true });
        axios
            .post(`/api/v1/situation/controller/box/${this.props.match.params.crud}/${this.props.match.params.id}/${this.props.values.formValues.box_fk_box_situation_id}`, { data: this.props.values.formValues })
            .then((res) => {
                if (res.data.status === 201) {
                    let labels: string = "";
                    for (const item of res.data.errors) {
                        labels += `> ${item.label}, `;
                    }
                    labels = labels.slice(0, -2)
                    Notification.error({
                        title: "Campos obrigatorios para avançar situação!",
                        description: labels,
                        placement: "bottomEnd",
                        duration: 3000,
                    });
                    this.setState({ loading: false });
                    return;
                }
                if (res.data.status === 200) {
                    this.props.onSubmit(
                        () => {
                            this.setState({ loading: true });
                            axios
                                .get(`/api/v1/situation/${status}/${this.props.match.params.id}`)
                                .then((async res => {
                                    if (res.status === 200 && res.data.status == 200) {
                                        await Notification.success({
                                            title: "Sucesso!",
                                            description: res.data.message,
                                            duration: 3500,
                                        });
                                        // setTimeout(() => {
                                        //     this.props.history.go(0)
                                        // }, 2000);
                                    }
                                    if (res.data.status == 201) {
                                        Notification.warning({
                                            title: "Atenção!",
                                            description: res.data.message,
                                        });
                                    }
                                }))
                                .finally(() => {
                                    this.setState({ loading: false });
                                });
                        },
                        () => {
                            this.setState({ loading: false });
                        },
                    );
                }
            })
            .catch((err) => {
                console.log('error :::: ', err)
            })

    }
    render() {
        return (<>
            <ConfirmModal
                loading={this.state.loading}
                onCancel={() => {
                    this.setState({
                        showSituation: false,
                    })
                }}
                cancelColor={"red"}
                showConfirm={false}
                cancelText={"Fechar"}
                onCloseButton={true}
                content={""}
                returnText={"Voltar Situação"}
                advancedText={"Avançar Situação"}
                title={"Avança situação de ordem de serviço"}

                show={this.state.showSituation}
            />
            {this.props.values.formValues.box_fk_box_situation_id == 1 && (<>
                <Button loading={this.state.loading} color={"violet"} onClick={() => this.trocaStatus('aguardando')} className="ml-4">
                    <i className="fas fa-tasks fa-fw mr-2"></i>
                    AGUARDANDO COLETA/ENTREGA
                </Button>
            </>)}

            {this.props.values.formValues.box_fk_box_situation_id == 2 && (<>
                <Button loading={this.state.loading} color={"violet"} onClick={() => this.trocaStatus('desfazer')} className="ml-4">
                    <i className="fas fa-undo-alt fa-fw mr-2"></i>
                    Desfazer Coleta
                </Button>
                <Button loading={this.state.loading} onClick={() => this.trocaStatus('finalizado')} color={"red"} className="ml-4">
                    <i className="fas fa-exclamation-triangle fa-fw mr-2"></i>
                    FINALIZADO
                </Button>
            </>)}

            {this.props.values.formValues.box_fk_box_situation_id > 2 && (<>
                <Button loading={this.state.loading} onClick={() => this.trocaStatus('reabrir')} color={"green"} className="ml-4">
                    <i className="fas fa-undo-alt fa-fw mr-2"></i>
                    Reabrir Embalagem
                </Button>
            </>)}
        </>)

    }
}

const BoxFooterWithRouter = withRouter(BoxFooterComponent);

export class BoxFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <BoxFooterWithRouter onSubmit={onSubmit} printId={this.globalState.oldValues.psm_id} values={this.globalState} />
            </>
        );
    };
}
