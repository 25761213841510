import dayjs from "dayjs";
import axios from "../../utilities/axios";
import { Event } from "../Event";
import { requiredColumns } from "../../utilities/situations/situationsFunctions";

export class DibiEvents extends Event {
    public settings = {
        saveButton: false,
    };
    public onEvents = {
        onChange: {
            listeners: ["psm_fk_business_unity_id", "psm_fk_company_id"],
            action: "montaCodigo",
        },
    };
    public montaCodigo = (value: any) => {
        if (this.values.psm_fk_business_unity_id && this.values.psm_fk_company_id) {
            const loadings: string[] = [...this.globalState.loadings];
            loadings.push("psm_os_number");
            this.setState({ loadings });
            axios
                .get("/api/v1/psm/next-code", {
                    params: {
                        bu: this.values.psm_fk_business_unity_id,
                        unidade: this.values.psm_fk_company_id,
                    },
                })
                .then((res) => {
                    if (res.data?.code) {
                        this.setFieldValue("psm_os_number", res.data?.code);
                    }
                })
                .finally(() => {
                    this.setState({ loadings: [] });
                });
        }
        // this.setFieldValue('psm_os_number','LPA0100000')
        // alert(this.values.psm_fk_business_unit_id)
        // alert(value);
    };

    // public onSave  = async (value: any) => {
    //     console.log ("Valor que vem no Value :::::::::::>", value);
    //     console.log ("Valor que vem no This Props :::::::::::>", this);
    // }


    public onInit = () => {
        const loadings: string[] = [];

        const { module: activeModule } = this.history.match.params;

        // this.setState("loadings", [

        // ]);

        if (activeModule === "vendas-dibi") {
            // this.setFieldValue("psm_fk_business_unity_id", { value: "9", label: "DIBI" }); //sempre ligar ao id do banco 
            this.setFieldValue("psm_fk_business_unity_id", 9); 
        }
        // this.setFieldValue('psm_purpose_product',{ value: "2", label: "REVENDA" })
        // this.setFieldValue('psm_purpose_product_copy',{ value: "2", label: "REVENDA" })
        // this.setFieldValue("psm_shipment_fk_type_frete_id", 4);
        // this.sectFieldValue("psm_sub_status", {value: "10", label: "AGUARDANDO SEPARAÇÃO DOS PRODUTOS"});
        this.setFieldValue("psm_taxes", "TODOS OS IMPOSTOS ESTÃO INCLUSOS");

        this.setFieldValue("psm_proposal_due_date", "10 DIAS");
        loadings.push("psm_user");

        this.setState({ loadings });
        if (!this.history.match.params.id) {
            this.setFieldValue("psm_status_fk_dibi_situation_id", 1)
        }

        axios
            .get("/api/v1/me")
            .then((subRes) => {
                var subData = subRes.data;
                //console.log(subData);
                if (subData) {
                    this.setFieldValue("psm_user", subData.user.user_name);
                }
            })
            .catch((error) => { })
            .finally(() => {
                const loadings = [...this.globalState.loadings];
                loadings.splice(loadings.indexOf("psm_user"));
                this.setState({ loadings });
            });

    }

    public onLoad = (value: any) => {
        requiredColumns(this.globalProps.values.psm_status_fk_dibi_situation_id, this,'dibi')
        var dadosPsm: any = this.globalProps.values

        this.setState("loadings", [
            "psm_fk_macro_region_id",
            "psm_microrregiao",
            "psm_fk_activity",
            "psm_commercial_cell",
            "psm_sector_commercial",
            "psm_internal",
            "psm_external",
            "psm_commercial_fk_person_contact_id",
            "psm_carriers_fk_person_id",
            "psm_fk_payment_term_id",
            "psm_keyacoount",
            "psm_relation_products",
            "psm_relation_services"

        ]);

        let variavelName = dadosPsm.psm_clients_fk_person_id != null ? "psm_clients_fk_person_id" : "psm_fk_person_id"

        // axios.get(`/api/v1/people/${dadosPsm?.[variavelName]}`)
        //     .then((res) => {
        //         if ((dadosPsm.psm_carriers_fk_person_id == undefined) && (dadosPsm.psm_carriers_fk_person_id == null)) {
        //             if (res.data.item.person_shipping_fk_person_id !== null) {
        //                 this.setFieldValue("psm_carriers_fk_person_id", res.data.item.person_shipping_fk_person_id)
        //             }
        //         }
        //         if (dadosPsm.psm_fk_payment_term_id == null) {
        //             if (res.data.item?.person_fk_payment_term_id !== null) {
        //                 this.setFieldValue("psm_fk_payment_term_id", res.data.item?.person_fk_payment_term_id);
        //             }
        //         }
        //         this.setFieldValue("psm_microrregiao", res.data.item.person_microregion);
        //         this.setFieldValue("psm_fk_activity", res.data.item.activity_sector.activity_sector_id);
        //         this.setFieldValue("psm_commercial_cell", res.data.item.person_contact_cellphone);

        //         if (dadosPsm?.psm_email_send_nfs == null) {
        //             this.setFieldValue("psm_email_send_nfs", res.data.item.person_email_nfs);
        //         }

        //         this.setFieldValue("psm_partial_billing", res.data.item.person_accept_income);
        //         this.setFieldValue("psm_receiving_limit_nfse", res.data.item.person_get_nfse);
        //         this.setFieldValue("psm_measuring_sheet_frs", res.data.item.person_use_frs);

        //         // this.setFieldValue("psm_obs_accept_income", res.data.item.person_obs_accept_income);
        //         // this.setFieldValue("psm_obs_receiving_limit", res.data.item.person_obs_get_nfse);
        //         // this.setFieldValue("psm_obs_measuring_sheet", res.data.item.person_obs_use_frs);

        //         if (dadosPsm?.psm_fk_macro_region_id == null) {
        //             this.setFieldValue("psm_fk_macro_region_id", res.data.item.macro_region.macro_region_id);
        //         }


        //         if (dadosPsm.psm_clients_fk_person_id !== null) {
        //             for (var k in res.data.item.person_sellers) {

        //                 if (res.data.item.person_sellers[k].seller.seller_type_of_seller !== 'K') {
        //                     if (res.data.item.person_sellers[k].seller.seller_is_extern == false) {
        //                         this.setFieldValue("psm_internal", res.data.item.person_sellers[k].seller.seller_name);
        //                         this.setFieldValue("psm_from_fk_seller_id", res.data.item.person_sellers[k].seller.seller_id);
        //                     }
        //                     if (res.data.item.person_sellers[k].seller.seller_is_extern == true) {
        //                         this.setFieldValue("psm_external", res.data.item.person_sellers[k].seller.seller_name);
        //                     }
        //                 }
        //                 if (res.data.item.person_sellers[k].seller.seller_type_of_seller === 'K') {
        //                     this.setFieldValue("psm_keyacoount", res.data.item.person_sellers[k].seller.seller_name);
        //                 }
        //             }
        //         }

        //     })
        //     .catch((e) => { })
        //     .finally(() => {
        //         this.setState("loadings", []);
        //     });

        if (dadosPsm.psm_taxes == null) {
            this.setFieldValue("psm_taxes", "TODOS OS IMPOSTOS JÁ ESTÃO INCLUSOS.")
        }
        // console.log("Valor do dados da PSM", dadosPsm)

        // console.log("Valor do dados da PSM", dadosPsm?.person?.person_fk_activity_sector_id)
        if (this.globalProps.match.url.includes('/add') != true){
            if (dadosPsm?.person?.person_fk_activity_sector_id != null || dadosPsm?.person?.person_fk_activity_sector_id != undefined){
                this.setFieldValue("psm_fk_activity_sector_id", dadosPsm.person.person_fk_activity_sector_id);
            }
        }
        //Data de Criação
        if (dadosPsm.psm_date == null || dadosPsm.psm_date == undefined) {
            let psmDate = dayjs().format("DD/MM/YYYY")

            this.setFieldValue("psm_date", psmDate);
        } else if (this.globalProps.match.url.includes('/add') == true) {
            this.setFieldValue("psm_date", dayjs().format("DD/MM/YYYY"));
        } else {
            let psmDate = dayjs(dadosPsm.psm_created_at).format("DD/MM/YYYY")

            this.setFieldValue("psm_date", psmDate);
        }

        // descomentar caso precise
        //if(dadosPsm.psm_proposal_due_date == null){
        //  this.setFieldValue("psm_proposal_due_date", "10 DIAS")
        //}
        // this.setFieldValue("psm_payment_condition", dadosPsm.payment_term.payment_term_id)
        // this.setFieldValue("psm_from_fk_seller_id", { value: 33, label: "VENDEDOR" });


        if (dadosPsm.psm_status_fk_dibi_situation_id == '3'){
            this.setField("psm_deadline", {
                readonly: true
            })
            this.setField("psm_from_fk_seller_id",{
                readonly: true
            })
        }
        const isProspect = this.globalProps.values.person?.person_type_is_prospect !== true ? false : true ;
        
        if([true].includes(isProspect)){
            this.setFieldValue('psm_client_is_prospect',true)
        }else{
            this.setFieldValue('psm_client_is_prospect',false)
        }
        
        // if (this.globalProps?.values?.person?.person_type_is_prospect == true){
        //     this.setFieldValue("psm_client_is_prospect", true)
        // }else{
        //     this.setFieldValue("psm_client_is_prospect", false)
        // }

        var budgetClient = dadosPsm.psm_clients_fk_person_id;
        this.globalProps.values.psm_clients_fk_person_id = budgetClient;
        if (budgetClient === null) {
            this.setFieldValue("psm_clients_fk_person_id", dadosPsm.psm_fk_person_id)
        }


        // if (dadosPsm.psm_shipment_fk_type_frete_id == null) {
        //     this.setFieldValue("psm_shipment_fk_type_frete_id", 4)
        // }

        this.setFieldValue("psm_fk_activity", dadosPsm.person?.person_fk_activity_sector_id)

        // if (dadosPsm.psm_final_value == null) {
        //     this.setFieldValue("psm_final_value", (dadosPsm.psm_conclusion_hours_to_finish * dadosPsm.psm_hour_value))
        // }

        // if (dadosPsm.psm_commercial_fk_person_contact_id == null) {
        //     this.setFieldProperty('psm_commercial_fk_person_contact_id', 'options', []);
        //     axios
        //         .get(`/api/v1/commercial/person_contacts/${dadosPsm.psm_clients_fk_person_id}`)
        //         .then((res) => {
        //             this.setFieldProperty("psm_commercial_fk_person_contact_id", 'options', res.data.items)
        //         })
        // }

        try {
            const maskDate = function (value: string) {
                if (value.includes('/')) {
                    value = value.split('/').join('')
                }
                let day = value.substring(0, 2);
                let month = value.substring(2, 4);
                let year = value.substring(4, 8);
                return `${day}-${month}-${year}`
            }


            if ([null, undefined, ''].includes(dadosPsm.psm_show_budget)) {
                this.setFieldValue('psm_billing_type', dadosPsm.psm_show_budget == 1 ? 'SERVIÇO' : 'SERVIÇO + PRODUTO');
            }
            // console.log('Entrei aqui ::: ', dadosPsm.psm_final_date_delivery)

            if ([11,4].includes(dadosPsm.psm_status_fk_situation_id)) {
            // if (dadosPsm.psm_status_fk_situation_id == 11) {
                this.setFieldValue("psm_final_date_delivery", '');
                this.setFieldValue('psm_lead_time', '');
            // } else {
            //     if ([null, undefined, ''].includes(dadosPsm.psm_final_date_delivery)) {
            //         console.log("entrei aqui")
            //         let dateEntrie = dayjs(maskDate(dadosPsm.psm_approve_date), 'DD-MM-YYYY').format('YYYY-MM-DD');
            //         let getDate = function (dataInicial: any, deadline: any) {
            //             let days = deadline
            //             let ignoredDays = [6, 0]
            //             let initialDate: any = dayjs(dataInicial, 'YYYY-MM-DD').format("MM-DD-YYYY")
            //             do {
            //                 // console.log("I'm here")
            //                 let getWeekDay = dayjs(initialDate).get("day")
            //                 // console.log('day week ::: ',{initialDate}, getWeekDay);

            //                 if (!ignoredDays.includes(getWeekDay)) {
            //                     days = days - 1
            //                 }
            //                 initialDate = dayjs(initialDate).add(1, 'day')
            //             }
            //             while (days > 0);
            //             let verification:any = dayjs(initialDate).get("day")
            //             if([6].includes(verification)){
            //                 initialDate = dayjs(initialDate).add(2, 'day')
            //             }
            //             if(verification == 0){
            //                 initialDate = dayjs(initialDate).add(1, 'day')
            //             }
            //             return dayjs(initialDate).format('DD/MM/YYYY');
            //         }
            //         // console.log("Estou aqui ::::>>>>>>>", dadosPsm)
            //         this.setFieldValue("psm_final_date_delivery", getDate(dateEntrie, dadosPsm.psm_deadline))
            //     }

                if (![null, undefined, ''].includes(dadosPsm.psm_final_date_delivery)) {
                    let dateNow: any = dayjs().format("MM-DD-YYYY");
                    let dateDelivery: any = maskDate(dadosPsm.psm_final_date_delivery);
                    dateDelivery = dayjs(dateDelivery, 'DD-MM-YYYY').format('MM-DD-YYYY')
                    let diffDate = dayjs(dateDelivery, "MM-DD-YYYY").diff(dateNow, 'day');
                    let resultDiff = function (dateDiff: any) {

                        if (dateDiff == 0) {
                            return "Hoje é o dia da Entrega";
                        }
                        if (Math.sign(dateDiff) == 1) {
                            return `Faltam ${dayjs(dateDelivery, "MM-DD-YYYY").diff(dateNow, 'day')} dias`
                        } else {
                            return `Passaram ${dayjs(dateDelivery, "MM-DD-YYYY").diff(dateNow, 'day')} dias`
                        }
                    }
                    this.setFieldValue("psm_final_date_delivery",dadosPsm.psm_final_date_delivery)
                    this.setFieldValue("psm_lead_time", dadosPsm.psm_status_fk_situation_id !== 16 ? resultDiff(diffDate) : 'PSM Finalizada');
                }
            }

        } catch (e) { console.log('error ::: ', e) }

        this.setState("loadings", [
            "psm_situation_date"
        ]);

        //Data da Situação
        if ((this.globalProps.match.url.includes('/add') != true) && (dadosPsm.psm_status_logs.length != 0) && (dadosPsm.psm_situation_date == null || dadosPsm.psm_situation_date == "")) {

            let i = dadosPsm.psm_status_logs.length - 1
            let date = dadosPsm.psm_status_logs[i].psm_status_log_date

            this.setFieldValue("psm_situation_date", date);
        }

        this.setState("loadings", [])
    };
}



export class DibiApprovalEvents extends Event {
    public onLoad = () => {
        requiredColumns(this.globalProps.values.psm_status_fk_dibi_situation_id, this,'dibi')
    }
}

export class DibiCloseEvents extends Event {
    public onLoad = () => {
        requiredColumns(this.globalProps.values.psm_status_fk_dibi_situation_id, this,'dibi')
    }
}

export class DibiCollectEvents extends Event {
    public onLoad = () => {
        requiredColumns(this.globalProps.values.psm_status_fk_dibi_situation_id, this,'dibi')
    }
}

export class DibiInvoicesEvents extends Event {
    public onLoad = () => {
        requiredColumns(this.globalProps.values.psm_status_fk_dibi_situation_id, this,'dibi')
    }
}

export class DibiPackagesEvents extends Event {
    public onLoad = () => {
        requiredColumns(this.globalProps.values.psm_status_fk_dibi_situation_id, this,'dibi')
    }
}