import { Notification } from "rsuite";
import { Event } from "../Event";
// import React from "react";
import _ from "lodash";
import dayjs from "dayjs";
import axios from "../../utilities/axios";


export class MaterialApprovedGridEvents extends Event {
    public onLoad = async (value: any, setField: any, setState: any, formValues: any, globalData: any,) => {

       this.setState('loadings', [
       'material_approved_grid_user_name',
       'material_approved_grid_date'
       ])
       const currentDate = dayjs().format('DD/MM/YYYY');
       const hourDate = dayjs().format("HH:mm:ss")
       this.setFieldValue('material_approved_grid_date', currentDate);
       this.setFieldValue('material_approved_grid_hour',hourDate);

       axios
       .get("/api/v1/me")
       .then((subRes) => {
           var subData = subRes.data;
           //console.log(subData);
           if (subData) {
               this.setFieldValue("material_approved_grid_user_name", subData.user.user_name);
           }
       })
       .catch((error) => {})
       .finally(() => {
           this.setState('loadings',[])
       });
    }
}