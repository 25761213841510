import _ from "lodash";
import axios from "../../utilities/axios";
import { requiredColumns } from "../../utilities/situations/situationsFunctions";
import { Event } from "../Event";
import dayjs from "dayjs";

export class ProductBoxEvents extends Event {
    public onInit = () => {
        if (!this.globalProps.location.pathname.includes('/view')) {
            axios
                .get("/api/v1/me")
                .then((subRes) => {
                    var subData = subRes.data;
                    //console.log(subData);
                    if (subData) {
                        this.setFieldValue("product_box_user", subData.user.user_name);
                    }
                })
                .catch((error) => { })
                .finally(() => {
                    const loadings = [...this.globalState.loadings];
                    loadings.splice(loadings.indexOf("psm_user"));
                    this.setState({ loadings });
                });
            this.setFieldValue("product_box_date_romanian", dayjs().format("DD/MM/YYYY HH:mm"));
            this.setFieldValue("product_box_volume", 1);
            this.setFieldValue("product_box_fk_box_situation_id", 1);
        }
    }

    public onLoad = async () => {
        requiredColumns(this.globalProps.values.product_box_fk_box_situation_id, this, 'product_box');
        if (this.globalProps.values.box_fk_box_situation_id > 1) {
            this.setField('product_box_allocated_package', {
                hidden: true
            })
        }

        if (!this.globalProps.match.url.includes('/add')) {
            this.setFieldValue("product_box_user", this.globalProps.values?.product_box_user.toLowerCase())
        }
        // if (!this.globalProps.location.pathname.includes('/add')) {
        //     if(this.globalProps.values.product_box_fk_box_situation_id > 1)
        //     this.setField('product_box_inclusions', {
        //         gridDelete: false,
        //         gridView: true
        //     })
        // }
        // requiredColumns(this.globalProps.values.box_fk_box_situation_id,this,'product_box')
        // this.setState("loadings",[]);
    }

}