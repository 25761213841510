import React from "react";
// import Cr from "react-cu"
import CurrencyInput from "react-currency-input-field";
import { InputGroup } from "rsuite";

export class NumberInput extends React.Component<any, {}> {
    public state = {
        value: this.props.value,
        fieldValue: undefined,
    };


    shouldComponentUpdate(nextProps: any, nextState: any) {
        if(nextProps.value !== this.props.value) {
            this.setState({
                value: nextProps.value,
                fieldValue: nextProps.value,
            });
        }
        return true;
    }

    onBlur = (event: any) => {
        const string = event.target.value;        
        if ((string !== null && string !== undefined) && string.length) {
            var value:any = parseFloat(string.split(".").join("").split(",").join("."));
            // if(this.props.min) {
            //     if(value < this.props.min) {
            //         value = this.props.min;
            //     }
            // }
            if(this.props.max) {
                if(value > this.props.max) {
                    value = this.props.max;
                }
            }
            if(!this.props.step || this.props.step <= 0){
                value = value.toFixed(0);
                value = parseFloat(value.split(".").join("").split(",").join("."));
            }
            
            // if(this.props.step){
            //     value = value.toFixed(this.props.step ?? 0);
            // }else{
            //     value = value.toFixed(0);
            // }
            // var value:any = parseFloat(value.split(".").join("").split(",").join("."));
            this.setState({ value,fieldValue:value });
            if (this.props.onChange) {
                this.props.onChange(value);
            }
            if(this.props.onBlur) {
                this.props.onBlur(value)
            }
        } else {
            this.setState({ value: undefined });
            if (this.props.onChange) {
                this.props.onChange(undefined);
            }
            if(this.props.onBlur) {
                this.props.onBlur(0)
            }
        }
    };

    onChange = (event: any) => {
        // var value = event.target.value;
    };

    onValueChange = (fieldValue: any) => {
        if(this.props.max) {
            if(fieldValue > this.props.max) {
                fieldValue = this.props.max;
            }
        }
        
        this.setState({ fieldValue: fieldValue });
    };

    renderFieldValue = () => {
        if(this.state.fieldValue && typeof this.state.fieldValue == 'number') {
          //  console.log("Teste")
            var valueField = JSON.stringify(this.state.fieldValue);
           // console.log( valueField.length)
            // if (typeof valueField === 'string') {
            //     console.log("Entrou");
            //     this.setState({
            //         fieldValue: 0
            //     });
            // }
          //  console.log(this.state.fieldValue)
            var value : number = parseFloat(this.state.fieldValue);
           // console.log(value)
            return value?.toFixed(this.props.step ?? 0);//2
        }
        return undefined;
    }

    renderValue = () => {
        if(this.state.value && typeof this.state.value == 'number') {
           // console.log("Teste 02");
            // var valueField = JSON.stringify(this.state.value);
            // console.log(valueField)
            // if (valueField == undefined) {
            //     this.setState({
            //         value: 0
            //     });
            // }
            var value : number = this.state.value;
            //console.log("Meu value", value)
            
            return value?.toFixed(this.props.step ?? 2);
            }
        if (typeof this.state.value == 'number' && this.state.value == 0){
            return 0;
        }      
        return undefined;
    }

    renderInput = () => {
        return (
            <CurrencyInput
                min="0"
                placeholder={this.props.disabled ? '0': "Digite um numero."}
                maxLength={this.props.maxLenght}
                className="rs-input"
                onChange={this.onChange}
                onValueChange={this.onValueChange}
                decimalSeparator={this.props.step === 0 ? undefined : ","}
                groupSeparator={this.props.punctuation === false ? " " : "."}
                defaultValue={this.renderValue()}
                value={this.renderFieldValue()}
                // onBlurCapture={() => {
                   
                // }}
                disabled={this.props.disabled}
                decimalsLimit={this.props.step ?? 2}
                onBlur={this.onBlur}
            />
        );
    };

    render() {
        return (<>
            <div className="number-input">
                {(this.props.preffix || this.props.suffix) && (
                    <InputGroup disabled={this.props.disabled}>
                        {this.props.preffix && <InputGroup.Addon>{this.props.preffix}</InputGroup.Addon>}
                        {this.renderInput()}
                        {this.props.suffix && <InputGroup.Addon>{this.props.suffix}</InputGroup.Addon>}
                    </InputGroup>
                )}
                {/* {this.props.value} */}
                {!this.props.preffix && !this.props.suffix && this.renderInput()}
                
            </div>
            {/* {this.state.value} */}
        </>  
        );
    }
}
