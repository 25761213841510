import { Event } from "../Event";
import axios from "../../utilities/axios";

export class MaterialRequisitionFkPsmIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings', [
            'material_requisition_number_psm_os_number',
            'material_requisition_fk_business_unity_id',
            'material_requisition_fk_person_id',
            'material_requisition_psm_dibi_situation',
            'material_requisition_comercial_status',
            'material_requisition_purpose_product',
            'material_requisition_priority',
            'material_requisition_deadline',
            'material_requisition_requisition_date',
            'material_requisition_fk_company_id',
            'material_requisition_fk_situation_id',
            'material_requisition_fk_dibi_situation_id',
            'material_requisition_manual_creation'
        ])

        this.setField('material_requisition_manual_creation', {
            disabled: true
        })

        if(this.globalProps.values.material_requisition_purpose_product !== null) {
            this.setFieldValue("material_requisition_purpose_product",)
        }
        axios.get(`/api/v1/commercials/` + value)
            .then(async (res) => {
                let itens = res.data.item;
                if (!itens.psm_os_number) {
                    await axios.get(`/api/v1/dibis/` + value)
                        .then((res) => {
                            itens = res.data.item;
                        })
                        .catch((e) => {
                            console.log(e)
                        })
                }
                let isTypePsm = itens.psm_os_number.substring(0,2);
                isTypePsm = isTypePsm.substring(1);
                const isCrud = isTypePsm == 'L' ? 'commercials' : 'dibi';
                const isSelectSituationPsm = isTypePsm == 'L' ? 'situation' : 'dibi_situation';

                this.setFieldValue('material_requisition_number_psm_os_number', itens.psm_os_number);
                this.setFieldValue('material_requisition_fk_business_unity_id', itens.psm_fk_business_unity_id);
                if(itens.business_unity.business_unity_abbreviation == 'LAB'){
                    this.setFieldValue('material_requisition_fk_person_id', itens.psm_fk_person_id);
                }else{
                    this.setFieldValue('material_requisition_fk_person_id', itens.person.person_id);
                }
                if (itens.psm_comercial_status !== null) {
                    this.setFieldValue('material_requisition_comercial_status', itens.psm_comercial_status);
                }
                if (itens.psm_purpose_product !== null) {
                    this.setFieldValue('material_requisition_purpose_product', itens.psm_purpose_product);
                }
                
                this.setFieldValue('material_requisition_priority', itens.psm_priority);
                this.setFieldValue('material_requisition_deadline', itens.psm_final_date_delivery);
                this.setFieldValue(`material_requisition_fk_${isCrud === 'commercials' ? 'situation' : 'dibi_situation'}_id`, itens[`${isSelectSituationPsm}`][`${isSelectSituationPsm}_id`]);
                // this.setFieldValue('material_requisition_fk_psm_id', value)
                this.setFieldValue('material_requisition_fk_company_id', itens.psm_fk_company_id)

                await axios.get(`/api/v1/material/generate-code/` + itens[`psm_fk_company_id`])
                    .then((res) => {
                        this.setFieldValue('material_requisition_os_number', res.data.code);
                    })
            })
            .finally(() => {
                this.setState('loadings', [])
            })
    }
}