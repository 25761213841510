import axios from "../../utilities/axios";
import { Event } from "../Event";
import dayjs from "dayjs";

export class ReceiptEvents extends Event {
    public settings = {
        saveButton: false,
    };
    public onEvents = {
        onChange: {
            listeners: ["psm_fk_business_unity_id", "psm_fk_company_id"],
            action: "montaCodigo",
        },
    };
    public montaCodigo = (value: any) => {
        if (this.values.psm_fk_business_unity_id && this.values.psm_fk_company_id) {
            const loadings: string[] = [...this.globalState.loadings];
            loadings.push("psm_os_number");
            this.setState({ loadings });
            axios
                .get("/api/v1/psm/next-code", {
                    params: {
                        bu: this.values.psm_fk_business_unity_id,
                        unidade: this.values.psm_fk_company_id,
                    },
                })
                .then((res) => {
                    if (res.data?.code) {
                        // console.log("Res", res)
                        this.setFieldValue("psm_os_number", res.data?.code);
                    }
                })
                .finally(() => {
                    this.setState({ loadings: [] });
                });
        }
        // this.setFieldValue('psm_os_number','LPA0100000')
        // alert(this.values.psm_fk_business_unit_id)
        // alert(value);
    };

    public onLoad = () => {
        var dadosPsm = this.globalProps.values;
        // console.log("Vamlor fpaokorf", dadosPsm)
        // if (this.globalProps.match.url.includes('/add') !== true) {
        //     if (dadosPsm.psm_fk_business_unity_id !== null || dadosPsm.psm_fk_business_unity_id !== undefined || dadosPsm.psm_fk_business_unity_id !== '') {
        //         this.setField("psm_fk_business_unity_id", {
        //             readOnly: true,
        //             disabled: true
        //         })
        //     }
        // }
        if (dadosPsm.psm_manual_note == false) {
            this.setField("psm_manual_note", {
                disabled: true
            })
        }
        axios
            .get(`/api/v1/situation/${this.globalProps.values.psm_status_fk_situation_id}/psm`)
            .then((res) => {

                let columns = res.data?.columns;
                for (let i in res.data?.columns) {
                    this.setField(i, {
                        readonly: columns[i].readonly
                    })
                }
            })
    };

    public onInit = () => {
        // alert("LOADED!");
        const loadings: string[] = [];


        const { module: activeModule } = this.history.match.params;
        // alert(JSON.stringify(activeModule));
        // alert(JSON.stringify(this.history));
        if (activeModule === "preventiva") {
            // var valueBU;
            //  // alert(JSON.stringify(this.history));
            //  //this.setFieldValue("psm_fk_business_unity_id", { value: 0, label:"N" });
            // this.setState("loadings",['psm_fk_business_unity_id'])
            //  axios
            // .get("api/v1/psm/getbu/" + activeModule)
            // .then(res => {
            //     if(res.status === 200 && res.data.status) {
            //         this.setFieldValue("psm_fk_business_unity_id", {value: res.data.result.value, label:res.data.result.label});
            //         valueBU = res.data.result.value;
            //     }
            // }).finally(() =>{
            //     this.setState("loadings",[]);
            // })
            // .catch(() => {});
            // this.setState("loadings",[]);
            // this.setFieldValue("psm_fk_business_unity_id", valueBU);


            this.setFieldValue("psm_fk_business_unity_id", { value: "7", label: "PREV" }); //sempre ligar ao id do banco (business_unities)
            // this.setFieldValue("psm_prev_type", { value: "1", label: "INTERNA" });
            this.setFieldValue("psm_prev_type", { value: "2", label: "EXTERNA" });
            // this.setFieldProperty("psm_fk_business_unit_id", "disabled", true);
            //  }
            //  this.setFieldValue("psm_fk_business_unity_id", { value: "7", label: "PREV" });

            // this.setFieldValue("psm_prev_type", { value: "1", label: "INTERNA" });
            // this.setFieldProperty("psm_fk_business_unit_id", "disabled", true);
        }
        if (!this.history.match.params.id) {
            this.setFieldValue("psm_status_fk_situation_id", 1)
        }
        this.setFieldValue("psm_shipment_type", { value: "1", label: "CIF" });
        this.setFieldValue("psm_status", { value: "1", label: "EM ORÇAMENTO" });
        // this.sectFieldValue("psm_sub_status", {value: "10", label: "AGUARDANDO SEPARAÇÃO DOS PRODUTOS"});
        this.setFieldValue("psm_taxes", "TODOS OS IMPOSTOS ESTÃO INCLUSOS");

        loadings.push("psm_user");

        this.setState({ loadings });

        axios
            .get("/api/v1/me")
            .then((subRes) => {
                var subData = subRes.data;
                //console.log(subData);
                if (subData) {
                    this.setFieldValue("psm_user", subData.user.user_name);
                }
            })
            .catch((error) => { })
            .finally(() => {
                const loadings = [...this.globalState.loadings];
                loadings.splice(loadings.indexOf("psm_user"));
                this.setState({ loadings });
            });

        //Data Abertura da PSM
        const dadosPsm = this.globalProps.values;
        let path = this.globalProps.location.pathname
        path = path.split("/")
        let pageType = path[path.length - 1]


        if (pageType == 'add') {
            this.setFieldValue("psm_date", dayjs().format("DD/MM/YYYY"));
        }

        // axios
        // .get("/api/v1/psm/horasTrabalhadas/" + this.globalProps.values.psm_id)
        // .then(res => {
        //     if(res.status === 200 && res.data.status) {
        //         this.setFieldValue("psm_displacement_worked_hours", res.data.calculo);
        //     }
        // })
        // .catch(() => {});
    };

}
function elseif(arg0: string) {
    throw new Error("Function not implemented.");
}

