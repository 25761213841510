import axios from "../../../utilities/axios";
import { Event } from "../../Event";
import dayjs from "dayjs";


export class PsmPurchaseOrderEvents extends Event {
    public onInit = () => {
        var dadosPsm = this.globalProps.values;
        axios
            .get("/api/v1/me")
            .then((subRes) => {
                var subData = subRes.data;
                //console.log(subData);
                if (subData) {

                    if (dadosPsm.psm_purchase_order_user_inclusion) {
                        this.setFieldValue("psm_purchase_order_user_inclusion", dadosPsm.psm_purchase_order_user_inclusion);
                    } else if (!dadosPsm.psm_purchase_order_user_inclusion) {
                        this.setFieldValue("psm_purchase_order_user_inclusion", subData.user.user_name);
                    }

                }
            })
            .catch((error) => { })
            .finally(() => {
                const loadings = [...this.globalState.loadings];
                loadings.splice(loadings.indexOf("psm_purchase_order_user_inclusion"));
                this.setState({ loadings });
            });

        if (dadosPsm.psm_purchase_order_inclusion_date) {
            this.setFieldValue("psm_purchase_order_inclusion_date", dadosPsm.psm_purchase_order_inclusion_date);
        } else if (!dadosPsm.psm_purchase_order_inclusion_date) {
            this.setFieldValue("psm_purchase_order_inclusion_date", dayjs().format("DD/MM/YYYY"));
        }
    }
}