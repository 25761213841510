import { Event } from "../Event"
import axios from "../../utilities/axios";
import dayjs from "dayjs";
import { requiredColumns } from "../../utilities/situations/situationsFunctions";
import { isNull } from "lodash";



export class MaterialRequisitionEvents extends Event {

       public onLoad = (value: any, setField: any, setState: any, formValues: any) => {

         
         
        if(this.globalProps.match.url.includes('/add')){
            this.setState('loadings', [
                'material_requisition_requisition_date',
                'material_requisition_request_user',
                'material_requisition_fk_material_requisition_situation_id'
            ])
            let currentDate = dayjs().format('DD-MM-YYYY hh:mm:ss.sss');
            this.setFieldValue('material_requisition_requisition_date',currentDate);
            axios
                .get("/api/v1/me")
                .then((subRes) => {
                    var subData = subRes.data;
                    //console.log(subData);
                    if (subData) {
                        this.setFieldValue("material_requisition_request_user", subData.user.user_name);
                    }
                })
                .catch((error) => {})
                .finally(() => {
                    this.setState('loadings',[])
                });
            this.setFieldValue('material_requisition_fk_material_requisition_situation_id', 1);
        }else {
            requiredColumns(this.globalProps.values.material_requisition_fk_material_requisition_situation_id, this,'material_requisition')
        
            if (this.globalProps.values.material_requisition_fk_material_requisition_situation_id >=2){
                this.setField("material_requisition_logistics", {
                    gridAdd: false,
                    gridDelete: false
                })
            }
         
        }

        // this.setState('loadings',[])
        if(this.globalProps.values.material_requisition_manual_creation == false){
            this.setField("material_requisition_manual_creation", {
                disabled: true
            })
        }
        if(this.globalProps.values.material_requisition_manual_creation == true && this.globalProps.values.material_requisition_number_psm_os_number != ''){
            this.setField("material_requisition_fk_business_unity_id",{
                readonly:true,
                disabled: true
            })
            this.setField("material_requisition_manual_creation", {
                disabled: true
            })
            this.setField("material_requisition_deadline",{
                readonly:true,
                disabled: true
            })
        
            this.setField("material_requisition_fk_person_id", {
                disabled: true
            })
            this.setField("material_requisition_fk_company_id",{
                disabled: true
            })            
        }       
      
    }
}