import _ from "lodash";
import { Event } from "../Event";
import { Notification } from "rsuite";

export class MaterialRequisitionLogisticEvents extends Event {
    public onLoad = (value: any, setField: any, setState: any) => {
        if (this.globalProps.parentValues.material_requisition_manual_creation == true) {
            const fieldRequired = ['material_requisition_logistic_total_quantity'];
            const fieldsBlocked = ['material_requisition_logistic_total_quantity', 'material_requisition_logistic_registered',
                'material_requisition_logistic_installed', 'material_requisition_logistic_item_description',
                'material_requisition_logistic_observation'];
            for (const item of fieldsBlocked) {
                this.setField(item, {
                    readonly: false
                })
            }

            for (const item of fieldRequired) {
                this.setField(item, {
                    required: true
                })
            }
            this.setField('material_requisition_logistic_fk_select_id', {
                disabled: false
            })
            this.setField("material_requisition_logistic_registered", {
                disabled: true
            })
            this.setField("material_requisition_logistic_installed", {
                disabled: true
            })
        }
        let valueItemsInsideRM = '';
        const materialAprrovedGrid = this.globalProps.values?.material_approved_grids;
        const valueTotalProducts = this.globalProps.values?.material_requisition_logistic_total_quantity
        if (materialAprrovedGrid?.length > 0) {
            for (const t in materialAprrovedGrid) {
                if (materialAprrovedGrid[t]?.material_approved_grid_quantity_met == valueTotalProducts) {
                    if (this.globalProps.values?.material_requisition_logistic_item_description
                        && this.globalProps.values?.material_requisition_logistic_fk_select_id !== 0
                        || this.globalProps.values?.material_requisition_logistic_fk_select_id !== undefined) {
                        this.setField('material_requisition_logistic_fk_select_id', {
                            hidden: true
                        })
                        this.setField('material_requisition_logistic_item_description', {
                            hidden: false
                        })
                    }
                }
            }
        }

        const globalPropsItemsInside = this.globalProps.parentValues ? this.globalProps.parentValues.material_requisition_logistics : 0;
        if (globalPropsItemsInside === undefined) {
            valueItemsInsideRM = '0';
            const parsedvalueItemsInsideRm = parseInt(valueItemsInsideRM + 1)
            this.setState('loadings', 'material_requisition_logistic_iten_rm');
            this.setFieldValue('material_requisition_logistic_iten_rm', parsedvalueItemsInsideRm);
            this.setState('loadings', [])

        } else {
            valueItemsInsideRM = this.globalProps.parentValues.material_requisition_logistics.length;
            const parsedvalueItemsInsideRm = parseInt(valueItemsInsideRM + 1)
            this.setState('loadings', 'material_requisition_logistic_iten_rm');
            this.setFieldValue('material_requisition_logistic_iten_rm', parsedvalueItemsInsideRm);
            this.setState('loadings', [])
        }
        if (this.globalProps.values.material_requisition_logistic_check_cancel_item == true) {
            this.setField('material_approved_grids', {
                gridAdd: false
            })
        }
    }
}


export class MaterialRequisitionLogisticQuantityToBuyEvents extends Event {
    public onBlur = async (value: any, setField: any, setState: any, formValues: any) => {

        // const valueTotal = this.globalProps.values.material_requisition_logistic_total_quantity;
        // const valueToBuy = value
        // const sumValuesQuantAccept = _.sumBy(this.globalProps.values?.material_approved_grids, function (item: any) {
        //     return item.material_approved_grid_quantity_met
        // })
        if (['', undefined, null].includes(value)) {
            this.setFieldValue('material_requisition_logistic_quantity_to_buy', 0)
        }

        // await this.setState('loadings', [
        //     'material_requisition_logistic_quantity_to_buy',
        //     'material_requisition_logistic_quantity_to_separate'
        // ])
        // if (valueToBuy > valueTotal) {
        //     Notification.error({
        //         title: 'Erro!',
        //         description: 'Não é possivel atribuir um valor maior que a Quant. Total!'
        //     })
        //     // this.setFieldValue('material_requisition_logistic_quantity_to_buy', 0);
        // } else {
        //     const calcSeparateProducts = valueTotal - (sumValuesQuantAccept + valueToBuy);
        //     this.setFieldValue('material_requisition_logistic_quantity_to_separate', calcSeparateProducts)
        // }

        // if (this.globalProps.values.material_requisition_logistic_quantity_to_separate < 0) {

        //     // this.setFieldValue('material_requisition_logistic_quantity_to_buy', 0);
        //     // this.setFieldValue('material_requisition_logistic_quantity_to_separate', 0);
        //     Notification.error({
        //         title: 'Erro!',
        //         description: 'Não é possivel atribuir um valor menor que a Quant okp. a Separar!'
        //     })
        // }

        // else if (this.globalProps.values.material_requisition_logistic_quantity_to_buy < 0) {

        //     // this.setFieldValue('material_requisition_logistic_quantity_to_buy', 0);
        //     // this.setFieldValue('material_requisition_logistic_quantity_to_separate', 0);
        //     Notification.error({
        //         title: 'Erro!',
        //         description: 'Não é possivel atribuir um valor menor que a Quant. a Separar!'
        //     })
        // }

        // this.setState('loadings', [])

        // this.setExternalFieldValues()
    }
}
// material_requisition_logistic_amount_returned
export class MaterialRequisitionLogisticAmountReturnedEvents extends Event {
    public onBlur = async (value: any, setField: any, setState: any, formValues: any) => {
        // console.log("Rodei no onblur do qnt devolvida",this.globalProps.values)
        if (this.globalProps.values.material_requisition_logistic_amount_returned < 0 || this.globalProps.values.material_requisition_logistic_amount_returned > this.globalProps.values.material_requisition_logistic_total_quantity) {
            this.setFieldValue('material_requisition_logistic_amount_returned', 0);
            Notification.error({
                title: 'Erro!',
                description: 'Não é possivel atribuir um valor maior que a Quant. Total ou valor negativo!'
            })
        }
    }
}

export class MaterialRequisitionLogisticCancelItemEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings', [
            'material_requisition_logistic_check_cancel_item'
        ])
        this.setFieldValue('material_requisition_logistic_check_cancel_item', true);
        this.setState('loadings', [])
    }
}