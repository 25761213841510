import classNames from "classnames";
import React from "react";
import { withRouter } from "react-router-dom";
import { Dropdown } from "rsuite";
import { isInPath } from "../../utilities/isInPath";
import { AuthContext, ModuleContext, UserContext } from "../authProvider/main";
import { ModuleShortcuts } from "./moduleShortcut";
import { NotificationsIcon } from "../notifications/main";
import { BreadCrumbs } from "../breadcrumbs/main";
// import { SearchBar } from "../search-bar/main";

class InnerTopbar extends React.Component<any, {}> {
    // static contextType = ModuleContext;
 
    logout = () => {
        this.props.auth.setAuth(null);
        this.props.history.push("/login");
        this.props.history.go(0);
    };

    goBack = () => {
        this.props.history.push("/dashboard");
    };

    onModuleClick = (moduleName: string) => {
        this.props.history.push("/dashboard/" + moduleName);
    };

    renderProfileButton() {
        var componentDeTeste = () => {
            if (this.props.user.data) {
                return (
                    <div className="clickable" style={{ textTransform: "capitalize", fontSize: 18, fontWeight: "bold", textAlign: "left", minWidth: 100 }}>
                        <i onClick={this.goBack} className="fas fa-fw fa-cog mr-2"></i>
                        {this.props.user.data.user_name}
                    </div>
                );
            } else {
                return null;
            }
        };

        return (
            <Dropdown placement="bottomEnd" renderTitle={componentDeTeste}>
                <Dropdown.Item onSelect={() => this.props.history.push("/dashboard/configuracoes/profile")}>
                    <i className="fas fa-fw fa-user"></i> Meu perfil
                </Dropdown.Item>
                <Dropdown.Item onSelect={this.logout}>
                    <i className="fas fa-fw fa-sign-out-alt"></i> Logout
                </Dropdown.Item>
            </Dropdown>
        );
    }

    renderModulesButton() {
        return (
            <div onClick={this.goBack} className="clickable" style={{ textTransform: "capitalize", fontSize: 18, fontWeight: "bold", minWidth: 200, textAlign: "right" }}>
                <i className="fas fa-fw fa-th-large mr-2"></i>
                {this.props.module.name ? this.props.module.name.split("-").join(" ") : ""}
            </div>
        );
    }

    public ioSubscription: any = null;

    render() {
        if (!this.props.auth.token && !this.props.module.name) {
            return null;
        }
        // if(this.ioSubscription === null) {
        //     this.ioSubscription = io(config.url);
        // this.ioSubscription.on
        //
        // }
        return (
            <div className={classNames("topbar", this.props.full ? "topbar-full" : "")} style={{ backgroundColor: this.props.module.color }}>
                <div style={{ width: "33%", display: "flex", alignItems: "center" }}>
                    {isInPath(this.props.location.pathname, "/dashboard") ? null : <i className="fas fa-bars"></i>}
                    <NotificationsIcon />

                    <BreadCrumbs />
                </div>

                {isInPath(this.props.location.pathname, "/dashboard") ? <div></div> : <ModuleShortcuts onClick={this.onModuleClick} />}
                {/* <div style={{ width: "33%", textAlign: "right" }}>{isInPath(this.props.location.pathname, "/dashboard") ? this.renderProfileButton() : this.renderModulesButton()}</div> */}
                <div style={{ width: "33%", textAlign: "right" }}>{ this.renderProfileButton()}</div>
            </div>
        );
    }
}

class Topbar extends React.Component<any, {}> {
    render() {
        return (
            <ModuleContext.Consumer>
                {(moduleContext) => {
                    return (
                        <AuthContext.Consumer>
                            {(auth) => {
                                let moduleVar: any = moduleContext;
                                if (isInPath(this.props.location.pathname, "/dashboard")) {
                                    moduleVar = { name: "", setModule: () => {} };
                                }
                                return (
                                    <UserContext.Consumer>
                                        {(user: any) => {
                                            // var timeout = setTimeout(() => {

                                            // })
                                            if (user.data?.user_name) {
                                                return (
                                                    <InnerTopbar
                                                        full={isInPath(this.props.location.pathname, "/dashboard")}
                                                        location={this.props.location}
                                                        history={this.props.history}
                                                        module={moduleVar}
                                                        auth={auth}
                                                        user={user}
                                                    />
                                                );
                                            } else {
                                            }
                                        }}
                                    </UserContext.Consumer>
                                );
                            }}
                        </AuthContext.Consumer>
                    );
                }}
            </ModuleContext.Consumer>
        );
    }
}

export default withRouter(Topbar);
